import React,{useEffect} from 'react';
import logo from "../images/logo_dark.png"

const Header= () => {

    const [scrolled,setScrolled]=React.useState(false);

    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 400 ){
            setScrolled(true);
        }
        else{
            setScrolled(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll',handleScroll)
    })

    let x=['header-float'];
    let shim = <div></div>
    if(scrolled){
        x.push('scrolled');
        shim = <div style={{ height: 135 }}></div>
    }

    return (
        <div>
        {shim}
        <div className={x.join(" ")}>
            <nav className="navbar navbar-expand-lg navbar-right navbar-dark">
                <a className="navbar-brand" href="/">
                    <div className="logo">
                        <div className="d-none d-md-block float-left" style={{ width: 120, height: 1 }}>&nbsp;</div>
                        <img src={logo} width="200" alt="logo" style={{ float: 'left' }}></img>
                    </div>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link text-white" href="/product">
                                <div className="menu-item">
                                    PRODUCT
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-white" href="/solutions">
                                <div className="menu-item">
                                    SOLUTIONS
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-white" href="/services">
                                <div className="menu-item">
                                    SERVICES
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-white" href="/contact">
                                <div className="menu-item-hot">
                                    CONTACT
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <div style={{ width: '6em' }}></div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        </div>
        )
}

export default Header