import React from "react"
import logosm from "../images/logosm.png"
import follow_li from "../images/followus_linkedin.png"
import follow_tw from "../images/followus_twitter.png"

export default function Footer() {
    return (
        <div className="footer-div">
            <table width="100%" cellPadding="10px">
                <tbody>
                <tr>
                    <td>
                        <img src={logosm} style={{ marginTop: 30, marginBottom: 30, float: 'right' }}/>
                    </td>
                    <td>
                        <div className="text-sm">
                            Automated performance insights from data you already have
                        </div>
                    </td>
                    <td align="right">
                        <div className="text-sm-email">
                            <div>info@roobricks.com</div>
                            <div>Follow us:
                                <div style={{ float: 'right' }}>
                                    <div className="follow-us">
                                        <a href="https://www.linkedin.com/company/roobricks" target="_blank">
                                            <img src={follow_li}></img>
                                        </a>
                                    </div>
                                    <div className="follow-us">
                                        <a href="https://twitter.com/RoobricksData" target="_blank">
                                            <img src={follow_tw}></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" cellPadding="10px">
                <tbody>
                <tr>
                    <td>
                        <div className="text-vsm">
                            &copy; 2020 Roobricks | All Rights Reserved
                        </div>
                    </td>
                    <td align="right">
                        <div className="text-vsm">
                            <a href="/tos" style={{ color: '#fff' }}>Terms of Service</a> | <a href="/privacy" style={{ color: '#fff' }}>Privacy Policy</a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}